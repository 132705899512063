
import { defineComponent } from 'vue'
import { getInvoice, InvoiceDto } from '@/api/invoice'
import { ElMessage } from 'element-plus'

export default defineComponent({
    name: 'Home',
    components: {},
    data () {
        return {
            searchValue: '',
            searchText: '',
            pageNum: 1,
            pageSize: 10,
            payDateSolt: '',
            payDateStart: '',
            payDateEnd: '',
            total: 0,
            tableData: [] as InvoiceDto[]
        }
    },
    created () {
        this.handleCurrentChange()
    },
    methods: {
        async handleCurrentChange () {
            const result = await getInvoice({
                page: this.pageNum,
                pageSize: this.pageSize,
                search: this.searchValue,
                startTime: this.payDateStart,
                endTime: this.payDateEnd
            })
            // console.log(result, 'result')
            this.total = result.total
            this.tableData = result.data
        },
        handleSizeChange () {
            this.pageNum = 1
            this.handleCurrentChange()
        },
        search () {
            console.log(this.payDateSolt)
            // this.searchValue = this.searchText
            this.payDateStart = this.payDateSolt ? this.payDateSolt[0] : ''
            this.payDateEnd = this.payDateSolt ? this.payDateSolt[1] : ''
            this.pageNum = 1
            this.handleCurrentChange()
        }
    }
})
